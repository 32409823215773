import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Card, Button, TextField, useScrollTrigger } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { buscarVagas, buscar } from '../../api/vagas';
import { useStyles } from './styles';


const Vagas = (props) => {
    const classes = useStyles();
    const [vagas, setVagas] = useState([]);
    const [ txtBusca, setTxtBusca ] = useState("");
    const history = useHistory();

    const { window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    const buscarVaga = () => {
        if(!txtBusca){
            buscarVagas()
            .then(vagas => {
                if (vagas.data) {
                    setVagas(vagas.data);
                }
            });
            return null
        };
        buscar({qry:txtBusca})
        .then(vagas => {
            if (vagas.data) {
                setVagas(vagas.data);
            }else{
                setVagas([]);
            }
        })
        .catch(setVagas([]));
    }

    useEffect(() => {
        buscarVagas()
            .then(vagas => {
                if (vagas.data) {
                    setVagas(vagas.data);
                }
            });
    }, []);

    return (
        <React.Fragment>
            <Grid className={[classes.buscador, !trigger&&classes.addMargin]} container>
                <Grid item xs={12}>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div>
                            <TextField value={txtBusca} onChange={(e) => setTxtBusca(e.target.value)} fullWidth id="busca" variant="outlined" placeholder="Digite o cargo ou área profissional" helperText="Exemplos: Administração, Tecnologia, Outros" />
                            <div>
                                <button onClick={() => buscarVaga()}>Procurar vagas</button>
                            </div>
                        </div>
                    </form>
                </Grid>
            </Grid>
            <Container style={{paddingTop:`20px`, paddingBottom:`20px`}}>
                <Grid container spacing={3}>
                    {vagas.length === 0 &&
                        <Grid item xs={12}>
                            <span className={classes.titulo}>
                                OPS, NÃO ENCONTRAMOS VAGAS PARA ESSA PESQUISA!
                            </span>
                        </Grid>
                    }
                    {
                        vagas.map((vaga, index) => {
                            return (
                                <Grid key={index} item xs={12}>
                                    <Card className={classes.vaga} onClick={()=> history.push(`/vaga/v${vaga.codigo}`)}>
                                        <Typography variant="h5" component="h2">
                                            {vaga.titulo_str}
                                        </Typography>
                                        <Typography component="p">
                                            {vaga.regime_str === "ESTAGIO" ? "Bolsa" : "Salário"} {vaga.salario_str}
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            {vaga.qtd_num} {vaga.qtd_num === 1 ? 'Vaga' : 'Vagas'}
                                        </Typography>
                                        <Button onClick={() => {
                                            if (!props.usuario) {
                                                history.push(`/candidato/v${vaga.codigo}`)
                                            } else {
                                                history.push('/vaga')
                                            }
                                        }} className={classes.btnCandidatar} variant="contained" color="secondary">
                                            Quero esta vaga
                                        </Button>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default Vagas;