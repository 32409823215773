import { autenticarUsuario, verificaAutenticacao } from '../api/usuario.js';
export const AUTENTICAR_USUARIO = 'AUTENTICAR_USUARIO';
export const UNAUTH = 'UNAUTH';
export const ADICIONAR_NICKNAME = 'ADICIONAR_NICKNAME';

export const autenticar = ( usuario, senha ) => {
	return async dispatch => {
		try {
			const auth = await autenticarUsuario(usuario,senha);
			if(!auth.token){
				throw new Error("Login inválido");
			}else{
				dispatch({ type: AUTENTICAR_USUARIO, payload: {token:auth.token, nome:auth.nome, email: auth.email, nicks: auth.nicks, apelido: auth.apelido} });
				localStorage.setItem('usrtk',JSON.stringify({tk:auth.token, nome:auth.nome, email:auth.email, nicks: auth.nicks, apelido: auth.apelido}));
				return auth;
			}
		} catch(err) {
			throw new Error("Login inválido");
		}
	}
};

export const logout = ( ) => {
	return async dispatch =>{
		try{
			dispatch({ type: UNAUTH });
			localStorage.removeItem('usrtk');
			
		} catch(err){
			console.log(err);
		}
	}
}

export const verificarAutenticacao = ( ) => {
	return async dispatch => {
		try {
			let storage = JSON.parse(localStorage.getItem('usrtk'));
			
			if(storage){
				const estaAutenticado = await verificaAutenticacao(storage.tk);
	
				if(estaAutenticado){
					dispatch({ type: AUTENTICAR_USUARIO, payload: {token:storage.tk, nome:storage.nome, email: storage.email, nicks: storage.nicks, apelido: storage.apelido}});
				}else{
					localStorage.removeItem('usrtk')
					dispatch({type: UNAUTH})
				}
			}else{
				dispatch({type: UNAUTH})
			}

		} catch(err) {
			console.log(err);
		}
	}
};
