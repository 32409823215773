import React from 'react';
import { yellow, gray } from '../assets/estilos/Colors';
import { makeStyles, Grid, Box } from "@material-ui/core";
import bgFooter from '../assets/img/bg_rodape.png';
import { WhatsApp, Instagram, Facebook } from '@material-ui/icons'


const useStyles = makeStyles((theme) => ({
    root: {
        background: `url(${bgFooter}) no-repeat`,
        backgroundSize: `cover`,
        fontFamily: `Poppins, sans-serif`,
        fontSize: `1rem`,
        position: `relative`,
        padding: `60px`,
        [theme.breakpoints.down('md')]: {
            backgroundPosition: 'center bottom',
            padding: '20px'
        },

    },
    container: {
        minHeight: `700px`,
        paddingTop: `300px`,
        // transform:`translateY(70%)`,
        // position:`relative`,
        [theme.breakpoints.down('sm')]: {
            paddingTop: `150px`
        },
    },
    t1: {
        fontFamily: `Poppins, sans-serif`,
        color: gray.default,
        fontSize: `3.6rem`,
        fontStyle: 'italic',
        textTransform: `uppercase`,
        fontWeight: `600`,
        display: `block`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.6rem'
        },
    },
    t2: {
        fontFamily: `Poppins, sans-serif`,
        color: gray.default,
        fontSize: `2rem`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8rem'
        },
        fontStyle: 'italic',
        textTransform: `uppercase`,
        fontWeight: `600`,
        display: `block`
    },
    txt: {
        maxWidth: '400px',
        margin: '0 0 0 auto',
        [theme.breakpoints.down(`sm`)]: {
            margin: `20px 0`
        }
    },
    icons: {
        '& a': {
            display: 'inline-block',
            position: `relative`,
            padding: `5px`,
            borderRadius: `50%`,
            width: `60px`,
            height: `60px`,
            color: yellow.logo,
            backgroundColor: `#ffffff`
        },
        '& a:nth-child(2)': {
            marginLeft: '20px',
            marginRight: '20px',
        },
        '& svg': {
            width: `2.5rem`,
            height: `2.5rem`,
            maxWidth: `100%`,
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`
        }
    }
}));

const Footer = (props) => {
    const classes = useStyles();

    return (
        <footer className={classes.root}>
            <Grid className={classes.container} alignItems="flex-start" container>
                <Grid item xs={12} md={6}>
                    <span className={classes.t1}>Conectando pessoas e empresas de valor!</span>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.txt}>
                        <span className={classes.t2}>Entre em contato</span>
                        <p>
                            Rua Professora Antonia Reginato Viana, 715 Curitiba, PR
                        </p>
                        <p>(41) 9 8838-1089</p>
                        <p>vagas@maisestagios.com.br</p>
                        <div className={classes.icons}>
                            <a href="https://www.facebook.com/maisestagios/" target="_blank" rel="noopener noreferrer">
                                <Facebook />
                            </a>
                            <a href="https://www.instagram.com/maisestagios/" target="_blank" rel="noopener noreferrer">
                                <Instagram />
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=5541988381089&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre..." target="_blank" rel="noopener noreferrer">
                                <WhatsApp />
                            </a>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Box textAlign={"center"}>
                <small>
                    28.306.309/0001-23<br />
                    MAIS ESTÁGIOS LTDA.
                </small>
            </Box>
        </footer>
    );
}


export default Footer;