import { makeStyles } from '@material-ui/core/styles';
import { yellow, gray } from '../../assets/estilos/Colors';

export const useStyles = makeStyles((theme) => ({
    titulo:{
        fontFamily:`Poppins, sans-serif`,
        textAlign:`center`,
        color: gray.default,
        fontSize:`2rem`,
        fontStyle:'italic',
        textTransform:`uppercase`,
        fontWeight:`600`,
        margin:'100px 20px',
        display:`block`,
        [theme.breakpoints.down('md')]: {
            fontSize:`2.2rem`,
            marginBottom:`10px`
        },
    },
    titulo_top:{
        marginTop:`20px`,
        marginBottom:`20px`,
        paddingTop:`20px`,
        width:`100%`,
        fontFamily:`Poppins, sans-serif`,
        fontSize:`1.2rem`,
        fontWeight:`600`,
        borderTop:`solid 1px #c1c1c1`
    },
    addMargin:{
        top:`140px!important`,
        [theme.breakpoints.down('sm')]: {
            top:`100px!important`
        },
    },
    buscador:{
        backgroundColor: yellow.logo,
        padding: 35,
        position:`-webkit-sticky`,
        // eslint-disable-next-line no-dupe-keys
        position:`sticky`,
        top:`0`,
        zIndex:`999`,
        transition:`all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
        [theme.breakpoints.down('md')]: {
            padding:'30px'
        },
        '& form':{
            margin:`0 auto`,
            border:`solid 3px ${yellow.logo}`,
            borderRadius:`8px`,
            fontFamily:`Poppins, sans-serif`,
            '& button':{
                [theme.breakpoints.down('sm')]: {
                    display:`none`
                },
                backgroundColor: gray.default,
                border:`none`,
                color:`#ffffff`,
                margin:`0 auto`,
                borderRadius:`30px`,
                minWidth:`150px`,
                fontSize:`1rem`,
                cursor:`pointer`,
                marginLeft:`20px`,
                padding:`18.5px 14px`,
                '&:focus':{
                    outline:`none`
                }
            },
            '& input':{
                backgroundColor:`#ffffff`,
                borderRadius:`30px`,
            },
            '& label.Mui-focused':{
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& fieldset':{
                border:`none`,
                borderRadius:`40px`
            },
            '& > div':{
                marginBottom:`30px`,
                justifyContent:`space-between`,
                display:`flex`,
            },
            '& .buscaEstado':{
                marginLeft:`20px`,
                width:`30%`
            },
            '& > div:last-child':{
                backgroundColor:`initial`,
                textAlign:`center`,
                margin:0
            }
        },
    },
    vaga:{
        padding:'20px',
        cursor:'pointer',
        '& .MuiAccordionSummary-content':{
            display:'block'
        }
    },
    textoDescricao:{
        overflow:'hidden',
        textOverflow:'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp:2,
        WebkitBoxOrient:'vertical'
    },
    btnCandidatar:{
        marginTop:'20px',
        position:'relative',
        zIndex:9
    },
    header:{
        backgroundColor:`#c1c1c1`,
        paddingTop:`30px`,
        paddingBottom:`30px`,
    }
}));