import React from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import { ButtonYellow } from '../../components/Button';
import { useStyles } from './styles';
// import { cadastrarUsuario } from '../../api/usuario';
import { connect } from 'react-redux';
import { criarAlerta } from '../../actions/AlertaActions';
// import { validationFields } from '../../utils/validations';
import { Helmet } from 'react-helmet'

// Imagens
import hero_candidato from '../../assets/img/hero_candidato.png';
import second_candidato from '../../assets/img/second_candidato.png';

const Candidato = ({ criarAlerta }) => {
    const classes = useStyles();
    // const [candidato, setCandidato] = useState({
    //     nome: '',
    //     cpf: '',
    //     cep: '',
    //     cidade: '',
    //     estado: '',
    //     email: '',
    //     senha: '',
    //     celular: '',
    //     curriculo: ''
    // })

    // const handleChange = (e) => {
    //     setCandidato({ ...candidato, [e.target.id]: e.target.value, [`${e.target.id}_error`]: '' })
    // }

    // const cadastrar = async () => {
    //     let errors = await validationFields({
    //         fields: {
    //             nome: candidato.nome,
    //             cpf: candidato.cpf,
    //             cep: candidato.cep,
    //             cidade: candidato.cidade,
    //             estado: candidato.estado,
    //             email: candidato.email,
    //             senha: candidato.senha,
    //             celular: candidato.celular,
    //         }
    //     });

    //     if (!errors.temErro) {
    //         cadastrarUsuario(candidato)
    //             .then(retorno => {
    //                 criarAlerta("Usuário cadastrado com sucesso!", "success");
    //                 setCandidato({ nome: '', cpf: '', cep: '', cidade: '', estado: '', email: '', senha: '', curriculo: '', celular: '' });
    //             })
    //             .catch(erro => {
    //                 criarAlerta(erro, "error");
    //             });
    //     } else {
    //         setCandidato({ ...candidato, ...errors.labels })
    //     }
    // }

    return (
        <div className={classes.home}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mais Estágios | Candidato</title>
                <link rel="canonical" href="http://maisestagios.com.br/candidato" />
            </Helmet>
            <Grid container className={classes.hero}>
                <Grid item xs={12}>
                    <div className="text-float">
                        Seu futuro de <span>Sucesso</span> <label>começa</label> aqui!
                        <div className="btn">
                            <ButtonYellow action={() => window.open('https://api.whatsapp.com/send?phone=5541988381089&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre...', '_blank')} text="FALE COM UM ESPECIALISTA" fontSize={`1.2rem`} />
                        </div>
                    </div>
                    <img src={hero_candidato} alt="hero" />
                </Grid>
            </Grid>
            <Container>
                <Grid container alignItems="center" className={classes.somosdiferentes}>
                    <Grid item xs={12} md={5}>
                        <img src={second_candidato} alt="candidatos" />
                    </Grid>
                    <Grid className="bloco-texto" item xs={12} md={6}>
                        <h3 className={classes.titulo}>Cadastre-se</h3>
                        <span className={classes.subtitulo}>Conheça nossas oportunidades para você.</span>
                        <Box alignItems="center">
                            <ButtonYellow action={() => window.open('https://people.talentbrand.com.br/c/mais-estagios')} text="VER VAGAS" fontSize="1.7rem" />
                        </Box>
                        {/* <form onSubmit={(e) => e.preventDefault()} >
                            <TextField 
                                value={candidato.nome} 
                                error={candidato.nome_error&&true} 
                                helperText={candidato.nome_error?candidato.nome_error:""}
                                onChange={handleChange}
                                fullWidth id="nome" 
                                placeholder="Nome Completo:" 
                                variant="outlined" 
                            />
                            <TextField 
                                value={candidato.cpf} 
                                error={candidato.cpf_error&&true} 
                                helperText={candidato.cpf_error?candidato.cpf_error:""}
                                onChange={handleChange} 
                                fullWidth id="cpf" 
                                placeholder="CPF:" 
                                variant="outlined" 
                            />
                            <TextField
                                value={candidato.cep}
                                error={candidato.cep_error&&true} 
                                helperText={candidato.cep_error?candidato.cep_error:""}
                                onChange={async (e) => {
                                    let cep = e.target.value; 
                                    if(e.target.value.length === 8){
                                        consultarCEP({cep:e.target.value})
                                        .then(dados => {
                                            setCandidato({...candidato, cep:cep, cidade:dados.localidade, estado: dados.uf});
                                        })
                                        .catch(err =>  {return null})
                                    }
                                    setCandidato({...candidato, cep: e.target.value, cep_error:`` })
                                }} 
                                fullWidth id="cep" 
                                placeholder="CEP:"
                                variant="outlined"
                            />
                            <div>
                                <TextField 
                                    value={candidato.cidade} 
                                    error={candidato.cidade_error&&true} 
                                    helperText={candidato.cidade_error?candidato.cidade_error:""}
                                    onChange={handleChange} 
                                    className="twofields" 
                                    id="cidade" 
                                    placeholder="Cidade:" 
                                    variant="outlined" 
                                />
                                <TextField 
                                    value={candidato.estado} 
                                    error={candidato.estado_error&&true} 
                                    helperText={candidato.estado_error?candidato.estado_error:""}
                                    onChange={handleChange} 
                                    className="twofields" 
                                    id="estado" 
                                    placeholder="Estado:" 
                                    variant="outlined"
                                />
                            </div>
                            <TextField 
                                value={candidato.celular} 
                                error={candidato.celular_error&&true} 
                                helperText={candidato.celular_error?candidato.celular_error:""}
                                onChange={handleChange} 
                                fullWidth 
                                id="celular" 
                                placeholder="Celular:" 
                                variant="outlined" 
                            />

                            <TextField 
                                value={candidato.email} 
                                error={candidato.email_error&&true} 
                                helperText={candidato.email_error?candidato.email_error:""}
                                onChange={handleChange} 
                                fullWidth 
                                id="email" 
                                placeholder="Email:" 
                                variant="outlined"
                            />
                            <TextField 
                                error={candidato.senha_error&&true}
                                helperText={candidato.senha_error?candidato.senha_error:""}
                                type="password" 
                                value={candidato.senha}
                                onChange={handleChange} 
                                fullWidth 
                                id="senha" 
                                placeholder="Digite uma senha:" 
                                variant="outlined"
                            />
                            <label style={{cursor:`pointer`}} htmlFor="curriculo">
                                <div style={{display:`flex`, alignItems:`center`}}>
                                    <span><b>Selecione seu currículo</b></span>
                                    <img style={{marginLeft:`20px`}} src={icon_pdf} alt="curriculo pdf" height="30px" />
                                </div>
                                <div>{candidato.curriculo && candidato.curriculo.name}</div>
                            </label>
                            <input 
                                style={{visibility:`hidden`}} 
                                id="curriculo" 
                                className={classes.inputPdf}
                                accept="application/pdf" 
                                type="file"
                                onChange={(e) => setCandidato({...candidato, curriculo:e.target.files[0]})}
                            />
                            <div>
                                <ButtonYellow action={cadastrar} text="ENVIAR" fontSize="1.3rem" />
                            </div>
                        </form> */}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default connect(null, { criarAlerta })(Candidato);