import axios from 'axios';

export const buscarVagas = () => {
    
    return axios.get(`https://maisestagios.com.br/api/vaga/listar`)
    .then(vagas => {return vagas})
    .catch(error => {return error});
    
}

export const buscar = ({codVaga, qry }) => {
    if(codVaga){
        return axios.get(`https://maisestagios.com.br/api/vaga/buscar/${codVaga}`)
        .then(vaga => {return vaga})
        .catch(err => {return err});
    }else if(qry){
        return axios.get(`https://maisestagios.com.br/api/vaga/pesquisar/${qry}`)
        .then(vaga => {return vaga})
        .catch(err => {return err});
    }
}

export const candidatar = ({codVaga, token}) => {
    
    if(!token){ throw new Error("Para se candidatar precisa estar logado!")}

    return axios({
        method:'PUT',
        url:`https://maisestagios.com.br/api/auth/vaga/candidatar/${codVaga}`,
        headers:{'x-access-token': token}
    })
    .then(candidatura => { return candidatura })
    .catch(error => { throw(error.response.data); });
}