import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {yellow, gray} from '../assets/estilos/Colors';

const useStyles = makeStyles({
    btn:{
        color:gray.default,
        backgroundColor:yellow.logo,
        padding: `15px 30px`,
        cursor:`pointer`,
        border:`none`,
        fontWeight:`bold`,
        borderRadius:`8px`,
        transition:`all linear .1s`,
        '&:focus':{
            outline:`none`
        },
        '&:hover':{
            boxShadow:`0px 1px 2px 2px rgba(0,0,0,0.2)`
        }
    }
});

export const ButtonYellow = ({action, text, fontSize}) => {
    const classes = useStyles();

    return(
        <button className={classes.btn} onClick={action} style={{fontSize:fontSize}}>
            {text}
        </button>
    );
}