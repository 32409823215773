import React, { useState } from 'react';
import { connect } from 'react-redux';
// import Logo from '../../assets/images/logo.svg';

import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {useStyles} from './styles';

import { autenticar } from '../../actions/AuthActions';
import { criarAlerta } from '../../actions/AlertaActions';
import { ButtonYellow } from '../../components/Button';

const ViewLogin = (props) => {

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const realizarLogin = () => {
    props.autenticar(email, senha)
      .then(r => {
        setTimeout(() => {
            if (props.location.state && props.location.state.back) {
                props.history.push({ pathname: props.location.state.back });
            } else {
                props.history.push('/');
            }
        }, 1000);
      })
      .catch(err => {
        props.criarAlerta("Login inválido!", "error");
      })
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography className={classes.titulo} component="h1" variant="h5">
          Seja bem vindo(a)!
        </Typography>
        <div className={classes.somosdiferentes}>
          <form noValidate onSubmit={e => e.preventDefault()}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="email"
              placeholder="Email"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <TextField
              required
              fullWidth
              variant="outlined"
              name="password"
              placeholder="Senha"
              type="password"
              id="password"
              onChange={(e) => setSenha(e.target.value)}
              autoComplete="current-password"
            />
            {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Lembrar"
                /> */}
            <div style={{textAlign:`center`}}>
              <ButtonYellow text="Entrar" fontSize="1.2rem" action={realizarLogin} />
            </div>
            <Grid container>
              <Grid item xs style={{textAlign:`left`}}>
                <Link className={classes.link} onClick={() => props.history.push('/candidato')} variant="body2">
                  {"Não tem uma conta? Cadastre-se"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps, { autenticar, criarAlerta })(ViewLogin);
