export const CRIAR_ALERTA = 'CRIAR_ALERTA';
export const LIMPAR_ALERTA = 'LIMPAR_ALERTA';

export const criarAlerta = ( mensagem, tipo ) => {
	return async dispatch => {
		try {
            dispatch({ type: CRIAR_ALERTA, payload: {mensagem: mensagem, tipo:tipo} });
		} catch(err) {
			throw new Error("Erro ao criar Alerta!");
		}
	}
};

export const limparAlerta = () => {
	return async dispatch => {
		try {
            dispatch({ type: LIMPAR_ALERTA, payload: {} });
		} catch(err) {
			throw new Error("Erro ao limpar Alerta!");
		}
	}
};
