import { makeStyles } from '@material-ui/core/styles';
import { gray, yellow } from '../../assets/estilos/Colors';
import listras from '../../assets/img/listras.png';
import bgconfia from '../../assets/img/quem_confia.png';

export const useStyles = makeStyles((theme) => ({
    titulo:{
        fontFamily:`Poppins, sans-serif`,
        textAlign:`center`,
        color: yellow.logo,
        fontSize:`3.2rem`,
        fontStyle:'italic',
        textTransform:`uppercase`,
        fontWeight:`600`,
        margin:'0 10px',
        display:`block`,
        [theme.breakpoints.down('sm')]: {
            fontSize:`2.2rem`,
            marginBottom:`10px`
        },
    },
    subtitulo:{
        fontFamily:`Poppins, sans-serif`,
        display:`block`,
        textAlign:`center`,
        color:gray.default,
        fontSize:`1.6rem`,
        fontWeight:'600',
        textTransform:`uppercase`,
        marginBottom:`50px`,
        [theme.breakpoints.down('sm')]: {
            fontSize:`1.3rem`
        },
    },
    home:{
        '& img':{
            maxWidth:`100%`,
        },
    },
    hero:{
        textAlign:"right",
        position:`relative`,
        '& img':{
            maxWidth: `80%`,
            [theme.breakpoints.down('sm')]: {
                maxWidth:'100%'
            },
        },
        '& .text-float':{
            fontFamily:`decalled`,
            position:`absolute`,
            textAlign:`center`,
            color:yellow.logo,
            fontSize:`3rem`,
            maxWidth:`500px`,
            top: '20%',
            left: '8%',
            '& span':{
                color: gray.default
            },
            '& .btn':{
                marginTop:`40px`
            },
            [theme.breakpoints.down('sm')]: {
                position:'static'
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& > div':{
                display:'table',
                '& > div, & img':{
                    width:`100%`,
                    display:`table-footer-group`
                },
                marginBottom:`100px`
            }
        },
    },
    experiencia:{
        paddingTop:`40px`,
        paddingBottom:`40px`,
        [theme.breakpoints.down('sm')]: {
            padding:'20px'
        },
    },
    eusou:{
        marginTop:`100px`,
        textAlign:`center`,
        '& button':{
            marginTop:`30px`
        },
        [theme.breakpoints.down('sm')]: {
            marginTop:`20px`,
            '&:last-child':{
                marginTop:`100px`
            }
        },
    },
    somosdiferentes:{
        paddingTop:'30px',
        paddingBottom:'40px',
        position:`relative`,
        [theme.breakpoints.down('sm')]: {
            padding:'20px'
        },
        '&::before':{
            content: `""`,
            display:`block`,
            position:`absolute`,
            bottom:0,
            right:0,
            background:`url(${listras}) no-repeat`,
            backgroundPosition:`bottom right`,
            width: `500px`,
            height:`420px`,
            [theme.breakpoints.down('sm')]: {
                opacity:`.1`

            },
        },
        '& .bloco-texto':{
            zIndex:`1`,
        },
        '& .bloco-texto > div:last-child':{
            textAlign:`center`
        },
        '& p':{
            fontFamily:`Poppins, sans-serif`,
            collor:gray.default,
            fontSize:'1.4rem',
            fontWeight:`300`,
            maxWidth:`500px`,
            marginLeft:`auto`,
            marginRight:`auto`
        },
        '& button':{
            marginTop:`30px`
        }
    },
    solucoes:{
        paddingTop:'60px',
        paddingBottom:'40px',
        textAlign:`center`,
        '& p':{
            fontFamily:`Poppins, sans-serif`,
            padding:"0 40px",
            fontSize:"1.2rem",
            fontWeight:`300`,
        },
        '& img, svg':{
            height:`120px`,
            marginBottom:`20px`
        },
    },
    svgYellow:{
        fill:"#fcce06"
    },
    card:{
        backgroundColor:yellow.logo,
        paddingBottom:`60px`,
        position:`relative`,
        overflow:`initial`,
        '& button':{
            backgroundColor:gray.default,
            color:yellow.logo,
            position:`absolute`,
            bottom:`-30px`,
            left:`50%`,
            transform:`translateX(-50%)`,
            width:"80%"
        }
    },
    confia:{
        background:`url(${bgconfia}) no-repeat`,
        backgroundSize:'cover',
        minHeight:'800px',
        '& .slick-slider':{
            marginTop:'60px'
        }
    },
    parceiros:{
        paddingTop:`150px`,
        paddingBottom:`60px`,
        '& .slick-slider':{
            marginTop:'60px'
        }
    }
}));