import React, { useEffect } from 'react';
import { Grid, Container, Card, Box } from '@material-ui/core';
import { ButtonYellow } from '../../components/Button';
import { useStyles } from './styles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

import CarrosselClientes from '../../components/CarrosselClientes';

// Imagens
import second_empresa from '../../assets/img/second_empresa.png';
import { Hidden } from '@material-ui/core';

const Empresa = () => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            duration: 800
        });
    }, [])

    return (
        <div className={classes.home}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mais Estágios | Empresa</title>
                <link rel="canonical" href="http://maisestagios.com.br/empresa" />
            </Helmet>
            <Container>
                <Grid container className={classes.hero} >
                    <Grid item xs={12} md={5} data-aos="fade-right">
                        <h1>
                            Vamos encontrar os <span>melhores talentos</span> para o seu negócio em <span>tempo recorde!</span>
                        </h1>
                        <span>
                            Nossas ferramentas e suporte especializado em recrutamento e seleção
                            de estagiários irá proporcionar para você uma contratação de qualidade.
                        </span>
                        <Box mt={5}>
                            <ButtonYellow action={() => window.open('https://api.whatsapp.com/send?phone=5541988381089&text=Ol%C3%A1%2', '_blank')} text="SOLICITAR CONTATO" fontSize={`0.8rem`} />
                        </Box>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item xs={12} md={5} data-aos="fade-left">
                        <img src="/hero_empresa.webp" alt="hero" />
                    </Grid>
                </Grid>
            </Container>

            <Container className={classes.containerClientes} data-aos="fade-left">
                <Box>
                    <Box textAlign="center" mt={10} mb={5}>
                        <h1>Empresas de todo o <u>Brasil</u> confiam na <u>Mais Estágios</u></h1>
                    </Box>
                    <CarrosselClientes />
                </Box>
            </Container>

            <section className={classes.containerTecnologia}>
                <Box mt={10} pt={15}>
                    <Container data-aos="fade-right" data-aos-anchor-placement="top-center">
                        <Grid container alignItems="flex-end">
                            <Hidden smDown>
                                <Grid item xs={12} md={7}>
                                    <Box display="flex">
                                        <img src="/tecnologia_empresa.webp" alt="Nossa tecnologia"></img>
                                    </Box>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={5} >
                                <Box p={'20px'}>
                                    <h1>Não somos <span>mais um</span>!</h1>
                                    <p>No mercado desde o ano 2014, temos a missão de INTEGRAR OS MELHORES ESTUDANTES as melhores empresas da sua região.</p>
                                    <p>Além disso, a MAIS ESTÁGIOS sempre acreditou que fazer negócios vai muito além de metas e resultados - é preciso transformar a vida das pessoas, começando por aquelas que trabalham por isso.</p>
                                    <p>Portanto, nossa cultura organizacional é extremamente voltada ao bem-estar, desenvolvimento e motivação dos nossos colaboradores e Clientes, dando todo apoio a quem confia em nosso trabalho  ( ALUNOS – ESCOLAS – EMPRESAS).</p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </section>

            <Container className={classes.contateAgora}>
                <Grid container>
                    <Grid item xs={12} data-aos="zoom-in">
                        <h1>Venha encontrar os <span><u>melhores talentos</u></span> para o seu negócio!</h1>
                    </Grid>
                    <Grid className={classes.blocoGray} item xs={12} md={6} data-aos="flip-left">
                        <div>
                            <h2>Vantagens em contratar <u>estagiário</u></h2>
                            <span style={{ color: `#ffffff` }} className={classes.subtitulo}>Programa de estágio</span>
                            <ul>
                                <li>
                                    Oportunidade de encontrar e desenvolver talentos para o futuro do
                                    seu negócio;
                                </li>
                                <li>
                                    Energia, entusiasmo, inovação para sua empresa;
                                </li>
                                <li>
                                    Garantia de redução de custos, encargos e riscos trabalhistas;
                                </li>
                                <li>
                                    Sem burocracia, com a administração completa da <b><i>Mais Estágios</i></b>;
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: `100px` }} data-aos="flip-right">
                                <Card className={classes.card} elevation={5}>
                                    <p>
                                        Demonstre seu propósito e empreendodorismo, contrate agora seu estagiário(a).
                                    </p>
                                    <p>
                                        <b>
                                            Seu estagiário de hoje pode ser o CEO amanhã!
                                        </b>
                                    </p>
                                    <ButtonYellow action={() => window.open('https://api.whatsapp.com/send?phone=5541988381089&text=Ol%C3%A1%2C%20quero%20contratar!', '_blank')} text="CONTRATAR" fontSize="1.7rem" />
                                </Card>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: `100px` }} data-aos="flip-right">
                                <Card className={classes.card} elevation={5}>
                                    <p>
                                        Já possui estagiário (a) em sua empresa? <br /> Transfira ou regularize agora seus contratos com a menor taxa do mercado!
                                    </p>
                                    <p>
                                        <b>
                                            É simples e rápido!
                                        </b>
                                    </p>
                                    <ButtonYellow action={() => window.open('https://app.pipefy.com/public/form/sRqYYORL', '_blank')} text="REGULARIZAR" fontSize="1.7rem" />
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container className={classes.somosdiferentes}>
                    <Grid item xs={12} md={5}>
                        <img src={second_empresa} alt="empresa" />
                    </Grid>
                    <Grid className="bloco-texto" item xs={12} md={6}>
                        <h3 className={classes.titulo}>Não encontrou o que precisa?</h3>
                        <span className={classes.subtitulo}>
                            ENTRE EM CONTATO AGORA MESMO!
                        </span>
                        <Box alignItems="center">
                            <ButtonYellow action={() => window.open('https://api.whatsapp.com/send?phone=5541988381089&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre...', '_blank')} text="ENTRAR EM CONTATO" fontSize="1.2rem" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Empresa;