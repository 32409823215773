import {
	CRIAR_ALERTA,
	LIMPAR_ALERTA
} from '../actions/AlertaActions';

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = { open: false, mensagem:{}, tipo:{} }, action) {
	switch(action.type) {
		case CRIAR_ALERTA:
			return { ...state, open: true, mensagem: action.payload.mensagem, tipo:action.payload.tipo };
		case LIMPAR_ALERTA:
			return { ...state, open: false, mensagem: "", tipo:"" };
		default:
			return state;
	}
}
