import axios from 'axios';

export const cadastrarUsuario = ( { nome, cpf, cep, cidade, estado, email, senha, curriculo, celular } ) => {

    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("cpf", cpf)
    formData.append("cep", cep)
    formData.append("cidade", cidade);
    formData.append("estado", estado);
    formData.append("email", email);
    formData.append("senha", senha);
    formData.append("file", curriculo);
    formData.append("celular", celular)
    formData.append("termos", true);

    let cadastro = axios.post(`https://maisestagios.com.br/api/usuario/cadastrar`, formData, {
        headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
        }
    })
    .then(cadastro => {
        return cadastro;
    })
    .catch(error => {
        throw(error.response.data);
    });

    return cadastro;
    
}

// Autenticando um usuario
export const autenticarUsuario = async (email, senha) => {
    var autenticacao = await axios({
        method:'post',
        url:`https://maisestagios.com.br/api/usuario/autenticar`,
        data: {
            "email":email,
            "senha":senha
        }
    })
    .then(response => response.status === 200 && response.data)
    .catch(error => console.log(error))

    return autenticacao;
}

// Validando se autenticacao esta ativa
export const verificaAutenticacao = async ( token ) => {
    
    if(!token)return false;

    var estaAutenticado = await axios({
        method:'post',
        url:`https://maisestagios.com.br/api/usuario/estaAutenticado`,
        headers:{'x-access-token': token}
    })
    .then(response => response.status === 200 && response.data)
    .catch(error => console.log(error))

    return estaAutenticado;
}
