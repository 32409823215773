import React , { useState } from 'react';
import { makeStyles, Grid, TextField } from '@material-ui/core';
import { ButtonYellow } from './Button';
import { yellow, gray } from '../assets/estilos/Colors';
import { criarAlerta } from '../actions/AlertaActions';
import { connect } from 'react-redux';
import listras from '../assets/img/listras.png';
import { validationFields } from '../utils/validations';

const styles = makeStyles(theme => ({
    titulo:{
        fontFamily:`Poppins, sans-serif`,
        textAlign:`center`,
        color: yellow.logo,
        fontSize:`3.2rem`,
        fontStyle:'italic',
        textTransform:`uppercase`,
        fontWeight:`600`,
        margin:'0 10px',
        display:`block`,
        [theme.breakpoints.down('md')]: {
            fontSize:`2.2rem`,
            marginBottom:`10px`
        },
    },
    subtitulo:{
        fontFamily:`Poppins, sans-serif`,
        display:`block`,
        textAlign:`center`,
        color:gray.default,
        fontSize:`1.6rem`,
        fontWeight:'600',
        textTransform:`uppercase`,
        marginBottom:`50px`,
        [theme.breakpoints.down('md')]: {
            fontSize:`1.3rem`
        },
    },
    novidades:{
        [theme.breakpoints.down('md')]: {
          padding:'20px'
        },
        '& form':{
            maxWidth:`700px`,
            margin:`0 auto`,
            border:`solid 3px ${yellow.logo}`,
            borderRadius:`8px`,
            padding:'60px',
            [theme.breakpoints.down('md')]: {
                padding:'30px'
            },
            '& button':{
                margin:`0 auto`
            },
            '& input':{
                backgroundColor:`#ffffff`,
                borderRadius:`30px`,
                paddingTop:`20px`
            },
            '& label.Mui-focused':{
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& fieldset':{
                border:`none`,
                borderRadius:`40px`
            },
            '& > div':{
                marginBottom:`30px`,
                justifyContent:`space-between`,
                display:`flex`,
            },
            '& .twofields':{
                width:`49%`
            },
            '& > div:last-child':{
                backgroundColor:`initial`,
                textAlign:`center`,
                margin:0
            }
        },
        background: `url(${listras}) no-repeat`,
        backgroundPosition:`bottom center`,
        paddingTop:`50px`,
        paddingBottom:`240px`
    }
}));

const NewsLetter = ( props ) => {

    const [ dados, setDados ] = useState({
        nome:'',
        email:''
    });

    const classes = styles();

    const participar = async () => {
        
        let errors = await validationFields({fields:{
            nome:dados.nome,
            email:dados.email
        }});

        if(!errors.temErro){
            props.criarAlerta("Obrigado, agora é so aguardar nossas novidades!", "success");
            // cadastrarNewsLetter(dados)
            // .then(retorno => {
            //     props.criarAlerta("Obrigado, agora é so aguardar nossas novidades!", "success");
            //     setDados({nome:'', email:''});
            // })
            // .catch(erro => {
            //     props.criarAlerta("Ops, ocorreu um erro, tente novamente mais tarde!", "error");
            // });
        }else{
            setDados({...dados, ...errors.labels})
        }
        
    }

    return(
        <Grid container className={classes.novidades}>
            <Grid item xs={12}>
                <span className={classes.titulo}>Fique por dentro das novidades</span>
                <span className={classes.subtitulo}>Deixe seu melhor email para receber novidades</span>
            </Grid>
            <Grid item xs={12}>
                <form id='newsletter' onSubmit={(e) => e.preventDefault()} >
                    <TextField fullWidth value={dados.nome} onChange={e => setDados({...dados,nome:e.target.value, nome_error:''})} id="nome" placeholder="Nome:" variant="outlined" error={dados.nome_error&&true} helperText={dados.nome_error?dados.nome_error:""} />
                    <TextField fullWidth value={dados.email} onChange={e => setDados({...dados,email:e.target.value, email_error:''})} id="email" placeholder="Seu email:" variant="outlined" error={dados.email_error&&true} helperText={dados.email_error?dados.email_error:""} />
                    <div onClick={() => participar()}>
                        <ButtonYellow text="RECEBER NOVIDADES" fontSize="1.3rem" />
                    </div>
                </form>
            </Grid>
        </Grid>

    );
}

export default connect(null, { criarAlerta })(NewsLetter);