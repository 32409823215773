import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Container, Paper, Grid, TextField } from '@material-ui/core';
import { cadastrarUsuario } from '../../api/usuario';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    container:{
        paddingTop:"40px",
        paddingBottom:"40px"
    },
    paper:{
        padding:'20px'
    },
    margin:{
        marginTop:"1rem",
        '&:last-child':{
            marginBottom:"1rem"
        }
    },
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Sobre você', 'Segurança', 'Curriculo prévio'];
}

function getStepContent(step , form, setForm) {
    switch (step) {
        case 0:
            return <FormSobre {...form} setForm={setForm} />;
        case 1:
            return <FormSeguranca {...form} setForm={setForm}/>;
        case 2:
            return <FormCurriculo {...form} setForm={setForm} />;
        default:
            return 'Sem passo';
    }
}

const enviarCadastro = (form, vaga, handleClick) => {
    cadastrarUsuario(form)
    .then(cadastro => {
        if(cadastro.status === 200){
            handleClick();
        }
    })
    .catch(error => {
        console.log(error)
    });
}

const Cadastro = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [form, setForm] = useState({
        nome:"",
        sobrenome:"",
        email:"",
        telefone:"",
        senha:"",
        senhaCompare:"",
        termos:true,
        curriculoPDF:""
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length - 1) enviarCadastro(form,2,handleClick);  
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            Parabéns, você concluiu todas as etapas, aguarde um momento...
                        </Typography>
                    </div>
                ) : (
                        <div>
                            <Container className={classes.container}>
                                <Grid container justify="center">
                                    <Grid item xs={12} md={8}>
                                        <Paper className={classes.paper}>
                                            {
                                                getStepContent(activeStep, form, setForm)
                                            }
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container>
                            <div>
                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                    Voltar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                                </Button>
                            </div>
                        </div>
                    )}
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                Usuário cadastrado com sucesso!
                </Alert>
            </Snackbar>
        </div>
    );
}

const FormSobre = ( props ) =>{
    const classes = useStyles();

    return(
        <form noValidate autoComplete="off">
            <TextField 
                className={classes.margin} 
                fullWidth 
                id="Nome" 
                label="Nome" 
                variant="outlined"
                value={props.nome}
                onChange={e => props.setForm({...props,nome:e.target.value})}
            />
            <TextField 
                className={classes.margin} 
                fullWidth 
                id="SobreNome" 
                label="Sobrenome" 
                variant="outlined"
                value={props.sobrenome}
                onChange={e => props.setForm({...props,sobrenome:e.target.value})} 
            />
            <TextField 
                className={classes.margin} 
                fullWidth 
                id="Email" 
                label="E-mail" 
                variant="outlined"
                value={props.email}
                onChange={e => props.setForm({...props,email:e.target.value})}
            />
            <TextField 
                className={classes.margin} 
                fullWidth 
                id="Telefone" 
                label="Telefone" 
                variant="outlined"
                value={props.telefone}
                onChange={e => props.setForm({...props,telefone:e.target.value})}
            />
        </form>
    );
}

const FormSeguranca = ( props ) => {
    const classes = useStyles();
    
    return(
        <form noValidate autoComplete="off">
            <TextField 
                className={classes.margin} 
                fullWidth
                type="password"
                id="Senha" 
                label="Senha" 
                variant="outlined"
                value={props.senha}
                onChange={e => props.setForm({...props,senha:e.target.value})}
            />
            <TextField 
                className={classes.margin} 
                fullWidth
                type="password"
                id="SenhaCompare" 
                label="Digite novamente" 
                variant="outlined"
                value={props.senhaCompare}
                onChange={e => props.setForm({...props,senhaCompare:e.target.value})} 
            />
        </form>
    );
}


const FormCurriculo = ( props ) => { 
    
    return(
        <input onChange={(e) => props.setForm({...props, curriculoPDF:e.target.files[0]})} accept="application/pdf" type="file" />
    );
}

export default Cadastro;