// import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { verificarAutenticacao } from '../actions/AuthActions';

function AuthValidator({ history, verificarAutenticacao }) {
    
    // useEffect(()=>{
    //     verificarAutenticacao();
    // },[]);

    // useEffect(() => {
    //     const unlisten = history.listen(() => {
    //         verificarAutenticacao();
    //     });

    //     return () => {
    //         unlisten();
    //     }
    // }, []);

    return (null);
}

export default compose(
    withRouter,
    connect(null, { verificarAutenticacao })
)(AuthValidator);
