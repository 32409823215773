import { makeStyles } from '@material-ui/core/styles';
import { gray, yellow } from '../../assets/estilos/Colors';
import bgfrase from '../../assets/img/bg_frase.png';

export const useStyles = makeStyles((theme) => ({
    titulo:{
        fontFamily:`Poppins, sans-serif`,
        textAlign:`center`,
        color: yellow.logo,
        fontSize:`3.2rem`,
        fontStyle:'italic',
        textTransform:`uppercase`,
        fontWeight:`600`,
        margin:'0 10px',
        display:`block`,
        [theme.breakpoints.down('sm')]: {
            fontSize:`2.2rem`,
            marginBottom:`10px`
        },
    },
    subtitulo:{
        fontFamily:`Poppins, sans-serif`,
        display:`block`,
        textAlign:`center`,
        color:gray.default,
        fontSize:`1.6rem`,
        fontWeight:'600',
        textTransform:`uppercase`,
        marginBottom:`50px`,
        [theme.breakpoints.down('sm')]: {
            fontSize:`1.3rem`
        },
    },
    link:{
        color:gray.default,
        textTransform:`uppercase`,
        cursor:`pointer`
    },
    container:{
        paddingTop:`70px`,
        paddingBottom:`70px`
    },
    hero:{
        textAlign:"right",
        position:`relative`,
        '& img':{
            maxWidth: `80%`,
            [theme.breakpoints.down('sm')]: {
                maxWidth:'100%'
            },
        },
        '& .text-float':{
            fontFamily:`decalled`,
            position:`absolute`,
            textAlign:`center`,
            color: gray.default,
            fontSize:`3rem`,
            maxWidth:`500px`,
            top: '20%',
            left: '8%',
            lineHeight:`4.5rem`,
            '& span':{
                color:yellow.logo,
                fontSize:`6rem`,
                display:`block`,
            },
            '& label':{
                color:yellow.logo,
            },
            [theme.breakpoints.down('sm')]: {
                position:'static',
                transform:`none`
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& > div':{
                display:'table',
                '& > div, & img':{
                    width:`100%`,
                    display:`table-footer-group`
                },
                marginBottom:`100px`
            }
        },
    },
    eusou:{
        marginTop:`100px`,
        textAlign:`center`,
        '& button':{
            marginTop:`30px`
        },
        [theme.breakpoints.down('sm')]: {
            marginTop:`20px`,
            '&:last-child':{
                marginTop:`100px`
            }
        },
    },
    somosdiferentes:{
        paddingTop:'30px',
        paddingBottom:'40px',
        position:`relative`,
        [theme.breakpoints.down('sm')]: {
            padding:'20px'
        },
        '& form':{
            maxWidth:`700px`,
            margin:`0 auto`,
            marginTop:`60px`,
            border:`solid 3px ${yellow.logo}`,
            borderRadius:`8px`,
            padding:'60px',
            '& .MuiFormHelperText-root.Mui-error':{
                textAlign:`left`,
                marginLeft:`0`,
                fontSize:`15px`
            },
            [theme.breakpoints.down('sm')]: {
                padding:'30px'
            },
            '& button':{
                margin:`0 auto`
            },
            '& input':{
                backgroundColor:`#ffffff`,
                borderRadius:`30px`,
                paddingTop:`20px`
            },
            '& label.Mui-focused':{
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& fieldset':{
                border:`none`,
                borderRadius:`40px`
            },
            '& > div':{
                marginBottom:`30px`,
                justifyContent:`space-between`,
                display:`flex`,
            },
            '& .twofields':{
                width:`49%`
            },
            '& > div:last-child':{
                backgroundColor:`initial`,
                textAlign:`center`,
                margin:0
            },
        },
        '& .bloco-texto > div:last-child':{
            textAlign:`center`
        },
        '& p':{
            fontFamily:`Poppins, sans-serif`,
            collor:gray.default,
            fontSize:'1.4rem',
            fontWeight:`300`,
            maxWidth:`500px`,
            marginLeft:`auto`,
            marginRight:`auto`
        },
        '& button':{
            marginTop:`30px`,
        }
    },
    solucoes:{
        paddingTop:'60px',
        paddingBottom:'40px',
        textAlign:`center`,
        '& p':{
            fontFamily:`Poppins, sans-serif`,
            padding:"0 40px",
            fontSize:"1.2rem",
            fontWeight:`300`,
        },
        '& img':{
            height:`120px`,
            marginBottom:`20px`
        },
    },
    confia:{
        background:`url(${bgfrase}) no-repeat`,
        backgroundSize:'cover',
        minHeight:'400px',
        textAlign:`center`,

        '& h5':{
            fontFamily:`Poppins, sans-serif`,
            fontSize:`2rem`,
            fontStyle:`italic`,
            color:yellow.logo
        }
    },
    card:{
        textAlign:`center`,
        fontFamily:`Poppins, sans-serif`,
        '& > div:first-child span':{
            color:yellow.logo,
            textTransform:`uppercase`,
            fontWeight:`600`
        } 
    },
    inputPdf:{
        backgroundColor:`transparent!important`,
        padding:'20px 0',
        borderRadius:"0!important",
        marginBottom:20
    }
}));