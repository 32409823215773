export const validationFields = ({fields}) => {
    let labels = {};
    let temErro = false;

    for(var [field, value] of Object.entries(fields)){
        if(value === null || value === ''){
            if(temErro === false){
                temErro = true;
            };

            labels[`${field}_error`] = "Peencha o campo!";
        }
    }

    return {
        labels,
        temErro
    };
}