import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { buscar } from '../../api/vagas';
import { useStyles } from './styles';
import { candidatar } from '../../api/vagas';
import { criarAlerta } from '../../actions/AlertaActions';


const Vagas = ( props ) => {
    const classes = useStyles();
    const [vaga, setVaga] = useState({});
    const history = useHistory();
    const { codVaga } = useParams();
    const regime = {
        'CTL':{label:`Clt`},
        'PJ':{label:`Pj`},
        'ESTAGIO':{label:`Estágio`},
        'A DEFINIR':{label:`A Definir`}
    };

    const enviarCandidatura = ( ) => {
        candidatar({codVaga:vaga.codigo, token:props.auth.token})
        .then(retorno => {
            retorno.status === 200?props.criarAlerta(retorno.data, "success"):props.criarAlerta(retorno.data, "warning");
        })
        .catch(err => {
            props.criarAlerta("Erro ao candidatar, tente novamente mais tarde!", "error");
        })
    }

    const bRegime = ( rg ) => {
        if(regime[rg]){
            return regime[rg].label;
        }else{
            return null;
        }
    }

    useEffect(() => {
        const buscarVaga = async () => {
            await buscar({codVaga})
            .then(vaga => {
                setVaga(vaga.data);
            })
            .catch(err => {
                console.log(err)
            })
        }
        buscarVaga();
    }, [codVaga]);

    if(!vaga.titulo_str){
        return(
            <React.Fragment>
                <span className={classes.titulo}>
                    OPS, ESSA VAGA NÃO ESTÁ MAIS DISPONÍVEL!
                </span>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className={classes.header}>
                <Container>
                    <span>{codVaga}</span>
                    <Typography variant="h5" component="h2">
                        {vaga.titulo_str}
                    </Typography>
                    <span>{bRegime(vaga.regime_enum)}</span>
                </Container>
            </div>
            <Container style={{paddingTop:`20px`, paddingBottom:`20px`}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography component="p">
                            {vaga.regime_str === "ESTAGIO" ? "Bolsa" : "Salário"} {vaga.salario_str.toLocaleString('pt-br',{style:`currency`, currency:'BRL'})}
                        </Typography>
                        <Typography component="p">
                            {vaga.qtd_num} {vaga.qtd_num === 1 ? 'Vaga' : 'Vagas'}
                        </Typography>
                        <Typography component="p">
                            {vaga.cep_str}
                        </Typography>
                        
                        {vaga.beneficios.length === 0 &&
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography component="p" className={classes.titulo_top}>
                                        Benefícios
                                    </Typography>
                                </Grid>
                                {vaga.beneficios.map((beneficio, index) => (
                                    <Grid item>
                                        {beneficio.icone_url}
                                    </Grid>
                                ))}
                            </Grid>
                        }
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography component="p" className={classes.titulo_top}>
                                    Descrição
                                </Typography>
                            </Grid>
                            <Grid item>
                                {vaga.descricao_str}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography component="p" className={classes.titulo_top}>
                                    Informações
                                </Typography>
                            </Grid>
                            <Grid item>
                                {vaga.informacoes_str}
                            </Grid>
                        </Grid>
                        <Button onClick={() => {
                            if (!props.auth.token) {
                                history.push({
                                    pathname:`/login`,
                                    state:{
                                        back: history.location.pathname
                                    }
                                })
                            } else {
                                enviarCandidatura();
                            }
                        }} className={classes.btnCandidatar} variant="contained" color="secondary">
                            Quero esta vaga
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = ( state ) => {
    return {
        auth:state.auth
    }
}

export default connect(mapStateToProps,{ criarAlerta })(Vagas);