import React from 'react';
import Slider from 'react-slick';
import '../assets/estilos/slick.css';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    carrosselParceiros:{
        '& img':{
            maxHeight:`90px`,
            width:`auto!important`
        },
        width:`100%`,
        '& .slick-slide > div':{
            height:`100%`,
            padding:`10px`
        }
    }
});


const CarrosselParceiros = ({props, text}) => {
    const classes = useStyles();

    var settings = {
        autoplay:true,
        dots:false,
        infinite:true,
        arrows:false,
        speed:500,
        slidesToShow:5,
        slidesToScrol:3,
        centerMode:true,
        responsive: [
            {
              breakpoint: 730,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots:false,
                arrows:false,
                centerMode: true
              }
            },
            {
                breakpoint: 940,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  dots:false,
                  arrows:false,
                  centerMode: true
                }
              }
        ]

    }

    return (
        <div className={classes.carrosselParceiros}>
            <Slider {...settings}>
            <img src="/logos/parceiros/bagozzi.png" alt="Bagozzi" />
                <img src="/logos/parceiros/estacio.png" alt="Estácio" />
                <img src="/logos/parceiros/fae.png" alt="FAE" />
                <img src="/logos/parceiros/esic.png" alt="Esic" />
                <img src="/logos/parceiros/uniandrade.png" alt="Uniandrade" />
                <img src="/logos/parceiros/opet.png" alt="Opet" />
                <img src="/logos/parceiros/positivo.png" alt="Positivo" />
                <img src="/logos/parceiros/pucpr.png" alt="Pucpr" />
                <img src="/logos/parceiros/tuiuti.png" alt="Tuiuti" />
                <img src="/logos/parceiros/ufpr.png" alt="Ufpr" />
                <img src="/logos/parceiros/unibrasil.jpg" alt="Unibrasil" />
                <img src="/logos/parceiros/unicesumar.png" alt="Unicesumar" />
                <img src="/logos/parceiros/unicuritiba.png" alt="Unicuritiba" />
                <img src="/logos/parceiros/uninter.png" alt="Uninter" />
                <img src="/logos/parceiros/utfpr.png" alt="Utfpr" />
            </Slider>
        </div>
    );
}

export default CarrosselParceiros;
