import React, { useState } from 'react';
import { Grid, TextField, Container } from '@material-ui/core';
import { ButtonYellow } from '../../components/Button';
import { useStyles } from './styles';
import { consultarCEP } from '../../api/utils';
import { cadastrarUsuario } from '../../api/usuario';
import { connect } from 'react-redux';
import { criarAlerta } from '../../actions/AlertaActions';


// Imagens
import icon_pdf from '../../assets/img/icon_pdf.png';

const Candidato = ({criarAlerta}) => {
    const classes = useStyles();
    const [ candidato, setCandidato] = useState({
        nome:'',
        cpf:'',
        cep:'',
        cidade:'',
        estado:'',
        email:'',
        senha:'',
        celular:'',
        curriculo:''
    })

    const cadastrar = () => {
        cadastrarUsuario(candidato)
        .then(retorno => {
            criarAlerta("Perfil atualizado com sucesso!", "success");
            setCandidato({nome:'',cpf:'',cep:'',cidade:'',estado:'',email:'',senha:'',curriculo:'', celular:''});
        })
        .catch(erro => {
            criarAlerta(erro, "error");
        });
    }
    
    return(
        <Container>
            <Grid container alignItems="center" className={classes.somosdiferente}>
                <Grid item xs={12}>
                    <form onSubmit={(e) => e.preventDefault()} >
                        <TextField value={candidato.nome} onChange={(e) => setCandidato({...candidato, nome: e.target.value })} fullWidth id="nome" label="Nome Completo:" variant="outlined" />
                        <TextField value={candidato.cpf} onChange={(e) => setCandidato({...candidato, cpf: e.target.value })} fullWidth id="cpf" label="CPF:" variant="outlined" />
                        <TextField
                            value={candidato.cep}
                            onChange={async (e) => {
                                let cep = e.target.value; 
                                if(e.target.value.length === 8){
                                    consultarCEP({cep:e.target.value})
                                    .then(dados => {
                                        setCandidato({...candidato, cep:cep, cidade:dados.localidade, estado: dados.uf});
                                    })
                                    .catch(err =>  {return null})
                                }
                                setCandidato({...candidato, cep: e.target.value })
                            }} 
                            fullWidth id="cep" 
                            label="CEP:"
                            variant="outlined"
                        />
                        <div>
                            <TextField value={candidato.cidade} onChange={(e) => setCandidato({...candidato, cidade: e.target.value })} className="twofields" id="cidade" label="Cidade:" variant="outlined" />
                            <TextField value={candidato.estado} onChange={(e) => setCandidato({...candidato, estado: e.target.value})} className="twofields" id="estado" label="Estado:" variant="outlined" />
                        </div>
                        <TextField value={candidato.celular} onChange={(e) => setCandidato({...candidato, celular: e.target.value })} fullWidth id="celular" label="Celular:" variant="outlined" />   
                        <TextField value={candidato.email} onChange={(e) => setCandidato({...candidato, email: e.target.value })} fullWidth id="email" label="Email:" variant="outlined" />
                        <TextField type="password" value={candidato.senha} onChange={(e) => setCandidato({...candidato, senha: e.target.value })} fullWidth id="senha" label="Digite uma senha:" variant="outlined" />
                        <label style={{cursor:`pointer`}} htmlFor="curriculo">
                            <div style={{display:`flex`, alignItems:`center`}}>
                                <span><b>Selecione seu currículo</b></span>
                                <img style={{marginLeft:`20px`}} src={icon_pdf} alt="curriculo pdf" height="30px" />
                            </div>
                            <div>{candidato.curriculo && candidato.curriculo.name}</div>
                        </label>
                        <input 
                            style={{visibility:`hidden`}} 
                            id="curriculo" 
                            className={classes.inputPdf} 
                            accept="application/pdf" 
                            type="file"
                            onChange={(e) => setCandidato({...candidato, curriculo:e.target.files[0]})}
                        />
                        <div>
                            <ButtonYellow action={cadastrar} text="ENVIAR" fontSize="1.3rem" />
                        </div>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}

export default connect(null, { criarAlerta })(Candidato);