import {
	AUTENTICAR_USUARIO,
	UNAUTH,
	ADICIONAR_NICKNAME
} from '../actions/AuthActions';

export default function Auth(state = { autenticado: false, nicks:{}, apelido:null }, action) {
	switch(action.type) {
		case AUTENTICAR_USUARIO:
			return { ...state, nome: action.payload.nome, email: action.payload.email, token: action.payload.token, autenticado: true, nicks: action.payload.nicks, apelido: action.payload.apelido };
		case ADICIONAR_NICKNAME:
			return { ...state,nicks: action.payload.nicks };
		case UNAUTH:
			return { ...state, nome: null, nicks:{}, apelido: null, token: null, autenticado: false };
		default:
			return state;
	}
}