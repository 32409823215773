import React from 'react';
import Slider from 'react-slick';
import '../assets/estilos/slick.css';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles({
    carrosselClientes: {
        '& img': {
            maxHeight: `90px`,
            width: `auto!important`
        },
        width: `100%`,
        '& .slick-slide > div': {
            height: `100%`,
            padding: `10px`
        }
    }
});


const CarrosselClientes = ({ props, text }) => {
    const classes = useStyles();

    var settings = {
        autoplay: true,
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScrol: 1,
        responsive: [
            {
                breakpoint: 730,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    centerMode: true
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    centerMode: true
                }
            }
        ]

    }

    return (
        <div className={classes.carrosselClientes}>
            <Slider {...settings}>
                <img src="/logos/clientes/logo_mec.webp" alt="MEC" />
                <img src="/logos/clientes/prf.webp" alt="PRF" />
                <img src="/logos/clientes/caern.webp" alt="CAERN" />
                <img src="/logos/clientes/adasa.webp" alt="ADASA" />
                <img src="/logos/clientes/cfess.webp" alt="CFESS" />
                <img src="/logos/clientes/tjdft.webp" alt="TJDFT" />
                <img src="/logos/clientes/ablcall.png" alt="ABL CALL" />
                <img src="/logos/clientes/amey.png" alt="Amey" />
                <img src="/logos/clientes/carrera.png" alt="Carrera" />
                <img src="/logos/clientes/knx.png" alt="Knx" />
                <img src="/logos/clientes/linkco.png" alt="Link Coworking" />
                <img src="/logos/clientes/bne.png" alt="BNE" />
                <img src="/logos/clientes/facilcon.png" alt="Facilcon" />
                <img src="/logos/clientes/rumonegocios.png" alt="Rumo Negócios" />
                <img src="/logos/clientes/nexcore.png" alt="Nexcore" />
                <img src="/logos/clientes/novafibra.png" alt="Nova Fibra" />
                <img src="/logos/clientes/pontomais.png" alt="Pontomais" />
                <img src="/logos/clientes/yorkers.png" alt="Yorkers" />
            </Slider>
        </div>
    );
}

export default CarrosselClientes;
