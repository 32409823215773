import React from 'react';
import { Grid, Card, CardHeader, CardContent, Container } from '@material-ui/core';
import { ButtonYellow } from '../../components/Button';
import { useStyles } from './styles';
import { Helmet } from 'react-helmet';

// Componentes
// import CarrosselClientes from '../../components/CarrosselClientes';
// import CarrosselParceiros from '../../components/CarrosselParceiros';

// Imagens
import hero_empresa from '../../assets/img/hero_empresa.png';
import somosdiferentes from '../../assets/img/somos_diferentes.png';
import NewsLetter from '../../components/NewsLetter';

const QuemSomos = () => {
    const classes = useStyles();

    return (
        <div className={classes.home}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mais Estágios | Quem Somos</title>
                <link rel="canonical" href="http://maisestagios.com.br/quem-somos" />
            </Helmet>
            <Grid container className={classes.hero}>
                <Grid item xs={12}>
                    <div className="text-float">
                        A Empresa
                    </div>
                    <img src={hero_empresa} alt="hero" />
                </Grid>
            </Grid>
            <Container>

                <Grid container spacing={3} className={classes.mvv}>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.card}>
                            <CardHeader title="Missão" />
                            <CardContent>
                                Ajudar empresas a encontrar e se conectar com pessoas talentosas para seus negócios. Ajudar jovens a iniciar sua carreira profissional.
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.card}>
                            <CardHeader title="Visão" />
                            <CardContent>
                                Tornar-se um dos principais integradores de profissionais ao mercado de trabalho nos próximos 5 anos.
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.card}>
                            <CardHeader title="Valores" />
                            <CardContent>
                                Respeito as pessoas e aos parceiros; <br />
                                Ética comercial; <br />
                                Excelência na entrega dos compromissos; <br />
                                Honestidade e transparência. <br />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container alignItems="center" className={classes.somosdiferentes}>
                    <Grid className="bloco-texto" item xs={12} md={6}>
                        <h3 className={classes.titulo}>Somos diferentes</h3>
                        <span className={classes.subtitulo}>Conheça nossas expertises</span>
                        <p>
                            Acreditamos no poder do compromisso e na fidelidade das relações. Entendemos sua urgência, respeitamos seu tempo, necessidade e investimentos. Nosso foco é seu sucesso!
                        </p>
                        <p>
                            Estamos prontos para encontrar os profissionais certos para sua empresa com toda a agilidade
                            e um atendimento de acordo com a sua necessidade.
                        </p>
                        <div>
                            <ButtonYellow action={() => window.open('https://api.whatsapp.com/send?phone=5541988381089&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre...', '_blank')} text="FALE COM UM ESPECIALISTA" fontSize={`1.2rem`} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <img src={somosdiferentes} alt="Somos diferentes" />
                    </Grid>
                </Grid>
            </Container>
            <Grid container alignItems="center" className={classes.confia}>
                <Grid item xs={12}>
                    <Container>
                        <h5>
                            Empresas de sucesso sempre estão olhando para o futuro. Invista em novos <span>talentos</span>, invista em <span>estagiários!</span>
                        </h5>
                    </Container>
                </Grid>
            </Grid>
            <Container>
                <Grid container spacing={4} className={classes.solucoes}>
                    <Grid item xs={12}>
                        <h4 className={classes.titulo}>Nossas Soluções</h4>
                        <span className={classes.subtitulo}>Conheça nossos serviços</span>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <svg className={classes.svgYellow} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 95 95">
                            <g>
                                <path class="st0" d="M42,36.5C42,36.5,42,36.5,42,36.5c1.5,4.8,4.5,8.1,8.1,8.1c0,0,0,0,0,0c0,0,0,0,0,0c3.5,0,6.6-3.2,8-8.1
		c0,0,0,0,0.1,0c0.7,0.1,1.2-1.1,1.5-2.7c0.3-1.6,0.2-2.9-0.5-3c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0-0.4c0-4.9-4-8.8-8.8-8.8
		c0,0,0,0,0,0c0,0,0,0,0,0c-4.9,0-8.8,4-8.8,8.8c0,0.2,0,0.3,0,0.4c-0.1,0-0.2,0-0.3,0c-0.7,0.1-0.7,1.4-0.5,3
		C40.8,35.3,41.3,36.6,42,36.5z"/>
                                <path class="st0" d="M58.3,44c-2.2,2.6-5.1,4-8.2,4h0c-3.1,0-6-1.5-8.2-4c-11.1,0.4-11.3,11.1-11.3,22.2c0,0,4.8,2.5,19.6,2.5
		c14.8,0,19.6-2.5,19.6-2.5C69.6,55.2,69.4,44.4,58.3,44z"/>
                                <path class="st0" d="M74.6,63.5c6.8-0.5,9.2-1.8,9.2-1.8c0-8.9-0.2-17.7-9.2-18c-1,1.1-2.2,2-3.4,2.5
		C74.1,51.2,74.6,57.9,74.6,63.5z"/>
                                <path class="st0" d="M75.4,32.9c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.2,0-0.4c0-4-3.2-7.2-7.2-7.2l0,0l0,0c-1.7,0-3.3,0.6-4.5,1.6
		c0.1,0.4,0.2,0.9,0.3,1.3c0.8,1.3,1.3,3.3,0.8,6.3c-0.4,2.4-1.2,3.9-2.1,4.9c3.1,0.7,5.4,2.3,7.1,4.2c2.2-0.8,4-3.1,4.9-6.3
		c0,0,0,0,0.1,0c0.6,0.1,1-0.9,1.2-2.2C76,34.1,75.9,33,75.4,32.9z"/>
                                <path class="st0" d="M25.5,63.5c-6.8-0.5-9.2-1.8-9.2-1.8c0-8.9,0.2-17.7,9.2-18c1,1.1,2.2,2,3.4,2.5C26,51.2,25.6,57.9,25.5,63.5z
		"/>
                                <path class="st0" d="M24.8,32.9c0.1,0,0.1,0,0.2,0c0-0.1,0-0.2,0-0.4c0-4,3.2-7.2,7.2-7.2c0,0,0,0,0,0l0,0c1.7,0,3.3,0.6,4.5,1.6
		c-0.1,0.4-0.3,0.9-0.3,1.3c-0.8,1.3-1.3,3.3-0.8,6.3c0.4,2.4,1.2,3.9,2.1,4.9c-3.1,0.7-5.4,2.3-7.1,4.2c-2.2-0.8-4-3.1-4.9-6.3
		c0,0,0,0,0,0c-0.6,0.1-1-0.9-1.2-2.2C24.2,34.1,24.2,33,24.8,32.9z"/>
                            </g>
                        </svg>

                        <span className={classes.subtitulo}>Estágios</span>
                        <p>
                            Mais do que um agente integrador, <b>compromisso</b> em selecionar pessoas talentosas para empresas de sucesso.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.cardSoluc} elevation={5}>
                            <svg className={classes.svgGray} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 67"><defs><style></style></defs><title>icon - contrate agora</title><path class="cls-1" d="M25.1,7.06,12,20.21v38H49.38V7.06ZM46.8,55.62H14.53V21.48H26.45V9.64H46.8Z" /><rect class="cls-1" x="29.46" y="18.83" width="14.7" height="2.65" /><rect class="cls-1" x="17.1" y="26.39" width="27.06" height="2.65" /><rect class="cls-1" x="17.1" y="31.69" width="27.06" height="2.65" /><rect class="cls-1" x="17.1" y="36.98" width="27.06" height="2.65" /><rect class="cls-1" x="17.1" y="42.28" width="27.06" height="2.65" /><rect class="cls-1" x="17.1" y="47.58" width="27.06" height="2.65" /></svg>
                            <span className={classes.subtitulo}>Contrate Agora</span>
                            <p>
                                Recrutamento e seleção personalizados e administração de estágios.
                            </p>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <svg className={classes.svgYellow} id="Modo_de_isolamento" data-name="Modo de isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 77"><defs fill="#fcce06"><style></style></defs><title>icon - vagas efetivas</title><path class="cls-1" d="M31,25.47l.06,0c1.4,4.87,4.46,8.09,8,8.09h0c3.55,0,6.6-3.22,8-8.09l.06,0c.7.13,1.21-1.14,1.49-2.71s.24-2.87-.46-3a.62.62,0,0,0-.27,0c0-.14,0-.29,0-.44a8.84,8.84,0,0,0-8.84-8.85h0a8.84,8.84,0,0,0-8.84,8.85c0,.15,0,.3,0,.44a.57.57,0,0,0-.26,0c-.71.13-.75,1.41-.47,3S30.3,25.6,31,25.47Z" /><path class="cls-1" d="M47.32,33a10.83,10.83,0,0,1-8.23,4h0a10.83,10.83,0,0,1-8.23-4c-11.07.37-11.34,11.14-11.34,22.17,0,0,4.77,2.51,19.57,2.51s19.57-2.51,19.57-2.51C58.65,44.15,58.38,33.38,47.32,33Z" /></svg>
                        <span className={classes.subtitulo}>Vagas Efetivas</span>
                        <p>
                            Processos seletivos personalizado para encontrar os melhores profissionais para seu negócio.
                        </p>
                    </Grid>
                </Grid>
            </Container>
            <NewsLetter />
        </div>
    );
}

export default QuemSomos;