import { makeStyles } from '@material-ui/core/styles';
import { gray, yellow } from '../../assets/estilos/Colors';
import bgfrase from '../../assets/img/bg_frase.png';

export const useStyles = makeStyles((theme) => ({
    titulo: {
        fontFamily: `Poppins, sans-serif`,
        textAlign: `center`,
        color: yellow.logo,
        fontSize: `3.2rem`,
        fontStyle: 'italic',
        textTransform: `uppercase`,
        fontWeight: `600`,
        margin: '0 10px',
        display: `block`,
        [theme.breakpoints.down('sm')]: {
            fontSize: `2.2rem`,
            marginBottom: `10px`
        },
    },
    subtitulo: {
        fontFamily: `Poppins, sans-serif`,
        display: `block`,
        textAlign: `center`,
        color: gray.default,
        fontSize: `1.6rem`,
        fontWeight: '600',
        textTransform: `uppercase`,
        marginBottom: `50px`,
        [theme.breakpoints.down('sm')]: {
            fontSize: `1.3rem`
        },
    },
    home: {
        '& img': {
            maxWidth: `100%`,
            [theme.breakpoints.down('sm')]: {
                marginTop: 50
            }
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    hero: {
        paddingTop: 100,
        '& h1': {
            fontFamily: `Poppins, sans-serif`,
            fontSize: `2.8rem`,
            fontStyle: 'italic',
            fontWeight: `800`,
            [theme.breakpoints.down('sm')]: {
                fontSize: `1.8rem`,
                marginBottom: `10px`
            },
            '& > span': {
                fontWeight: `800`,
                color: yellow.logo,
                textDecoration: 'underline',
            }
        },
        '& span': {
            fontFamily: `Poppins, sans-serif`,
            fontWeight: `300`,
        }
    },
    containerClientes: {
        '& h1': {
            fontFamily: `Poppins, sans-serif`,
            fontWeight: 600,
            '& u': {
                color: yellow.logo
            }
        }
    },
    containerTecnologia: {
        backgroundColor: '#FFDB3D',
        '& h1': {
            fontFamily: `Poppins, sans-serif`,
            fontSize: `2rem`,
            fontStyle: 'italic',
            fontWeight: 600,
            '& > span': {
                color: '#FFFFFF',
                textDecoration: 'underline',
            }
        },
        '& p': {
            fontWeight: 300,
            fontSize: '18px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 100
        },
    },
    eusou: {
        marginTop: `100px`,
        textAlign: `center`,
        '& button': {
            marginTop: `30px`
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: `20px`,
            '&:last-child': {
                marginTop: `100px`
            }
        },
    },
    somosdiferentes: {
        paddingTop: '30px',
        paddingBottom: '40px',
        position: `relative`,
        [theme.breakpoints.down('sm')]: {
            padding: '0px'
        },
        '& form': {
            maxWidth: `700px`,
            margin: `0 auto`,
            border: `solid 3px ${yellow.logo}`,
            borderRadius: `8px`,
            padding: '60px',
            [theme.breakpoints.down('sm')]: {
                padding: `20px 10px`
            },
            '& .MuiFormHelperText-root.Mui-error': {
                textAlign: `left`,
                marginLeft: `0`,
                fontSize: `15px`
            },
            '& button': {
                margin: `0 auto`
            },
            '& input': {
                backgroundColor: `#ffffff`,
                borderRadius: `30px`,
                paddingTop: `20px`
            },
            '& label.Mui-focused': {
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: `translate(14px, 8px) scale(0.75)`
            },
            '& fieldset': {
                border: `none`,
                borderRadius: `40px`
            },
            '& > div': {
                marginBottom: `30px`,
                justifyContent: `space-between`,
                display: `flex`,
            },
            '& .twofields': {
                width: `49%`
            },
            '& > div:last-child': {
                backgroundColor: `initial`,
                textAlign: `center`,
                margin: 0
            }
        },
        '& .bloco-texto > div:last-child': {
            textAlign: `center`
        },
        '& p': {
            fontFamily: `Poppins, sans-serif`,
            collor: gray.default,
            fontSize: '1.4rem',
            fontWeight: `300`,
            maxWidth: `500px`,
            marginLeft: `auto`,
            marginRight: `auto`
        },
    },
    solucoes: {
        paddingTop: '60px',
        paddingBottom: '40px',
        textAlign: `center`,
        '& p': {
            fontFamily: `Poppins, sans-serif`,
            padding: "0 40px",
            fontSize: "1.2rem",
            fontWeight: `300`,
        },
        '& img': {
            height: `120px`,
            marginBottom: `20px`
        },
    },
    confia: {
        background: `url(${bgfrase}) no-repeat`,
        backgroundSize: 'cover',
        minHeight: '400px',
        textAlign: `center`,

        '& h5': {
            fontFamily: `Poppins, sans-serif`,
            fontSize: `2rem`,
            fontStyle: `italic`,
            color: yellow.logo
        }
    },
    vantagens: {
        marginBottom: `50px`,
        padding: 50,
        fontSize: `1rem`,
        fontFamily: `Poppins, sans-serif`,
        '& p': {
            marginBottom: `40px`
        },
        '& p, p > span': {
            textAlign: `left`,
        },
        '& p > span': {
            fontSize: `1.2rem`,
            marginBottom: `10px`
        }
    },
    contateAgora: {
        marginBottom: `100px`,
        marginTop: `50px`,
        '& h1': {
            fontFamily: `Poppins, sans-serif`,
            textAlign: `center`,
            fontSize: `2.3rem`,
            lineHeight: `4.5rem`,
            fontWeight: 600,
            fontStyle: 'italic',
            color: gray.default,
            marginBottom: `100px`,
            '& span': {
                color: yellow.logo
            }
        },
        '& h2': {
            textAlign: 'center',
            fontFamily: `Poppins, sans-serif`,
            fontStyle: 'italic',
            fontWeight: 800,
            fontSize: '2rem',
            '& u': {
                color: yellow.logo,
            }
        }
    },
    card: {
        [theme.breakpoints.down('sm')]: {
            marginTop: `20px`,
        },
        backgroundColor: yellow.logo,
        textAlign: `center`,
        padding: `10px 10px 40px 10px`,
        position: `relative`,
        overflow: `initial`,
        fontSize: `1.5rem`,
        maxWidth: `450px`,
        marginLeft: `auto`,
        marginRight: `auto`,
        '& button': {
            backgroundColor: gray.default,
            color: yellow.logo,
            position: `absolute`,
            bottom: `-30px`,
            left: `50%`,
            transform: `translateX(-50%)`,
            maxWidth: `80%`
        }
    },
    blocoGray: {
        backgroundColor: gray.default,
        color: `#ffffff`,
        padding: `50px`,
        fontSize: `1.2rem`,
        [theme.breakpoints.down('sm')]: {
            padding: '20px 15px'
        },
        '& li': {
            marginBottom: `20px`
        }
    }

}));