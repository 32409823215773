import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import configureStore from './ConfigureStore';

// Componentes
import Header from './components/Header';
import Alerta from './components/Alerta';
import AuthValidator from './components/AuthValidator';

// Containers
import Home from './containers/home';
import Vagas from './containers/vagas';
import DetalheVaga from './containers/vagas/DetalheVaga';
import QuemSomos from './containers/quem-somos';
import Cadastro from './containers/cadastro';
import Footer from './components/Footer';
import Candidato from './containers/candidato';
import Empresa from './containers/empresa';
import ScrollToTop from './components/ScrollToTop';
import Login from './containers/login/Login';
// import Signin from './containers/login/Signin';
import Perfil from './containers/candidato/Perfil';

const Master = ( { children } ) => {
  return(
    <main>
      <CssBaseline />
      <Header />
      {children}
      <Footer />
    </main>
  )
}

const store = configureStore();

const App = ( ) => {
  return (
    <Provider store={store}>
      <Router>
        <AuthValidator />
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Master>
              <Home />
            </Master>
          </Route>
          <Route path="/caern-superior" component={()=>{
            window.location.href = 'https://people.talentbrand.com.br/jobs/mais-estagios-processo-seletivo-caern-2022-superior-9fbf'
          }} />
          <Route path="/caern-tecnico" component={()=>{
            window.location.href = 'https://people.talentbrand.com.br/jobs/mais-estagios-processo-seletivo-caern-2022-tecnico-fe2e'
          }} /> 
          <Route path="/vagas">
            <Master>
              <Vagas />
            </Master>
          </Route>
          <Route path="/vaga/:codVaga">
            <Master>
              <DetalheVaga />
            </Master>
          </Route>
          <Route path="/cadastro">
            <Master>
              <Cadastro />
            </Master>
          </Route>
          <Route path="/quem-somos">
            <Master>
              <QuemSomos />
            </Master>
          </Route>
          <Route path="/candidato">
            <Master>
              <Candidato />
            </Master>
          </Route>
          <Route path="/perfil">
            <Master>
              <Perfil />
            </Master>
          </Route>
          <Route path="/empresa">
            <Master>
              <Empresa />
            </Master>
          </Route>
          <Route path="/contato">
            <Master>
              <Cadastro />
            </Master>
          </Route>
          <Route path="/login" render={props => <Master><Login {...props} /></Master>} />
          {/* <Route path="/signin" render={props => <Signin {...props} />} /> */}
        </Switch>
      </Router>
      <Alerta />
    </Provider>
  );
}

export default App;